import React from "react";
import CTA from "src/components/CTA";
import { PROJECT_DEV_2021 } from "src/helpers/config";

import "./WaitingList.scss";

const WaitingList = () => {
  return (
    <div className="waiting-list">
      <div className="hero">
        <h1 className="hero-title">
          <div className="image">
            <img
              loading="lazy"
              src="/images/logo-dev-2021-black.png"
              alt={PROJECT_DEV_2021}
            />
          </div>
        </h1>
        <p className="hero-paragraph">
          Em 2021 eu quero te mostrar o caminho para se tornar um desenvolvedor
          e caso você já seja um desenvolvedor eu vou te mostrar como alcançar
          melhores salários, estando preparado para as melhores vagas do
          mercado.
        </p>
        <p className="hero-paragraph">
          Esse será um evento 100% online e 100% gratuito que acontecerá nos
          dias <span className="highlight">25, 27 e 29 de Janeiro</span>.
        </p>
        <p className="hero-paragraph ">
          Espero você lá,&nbsp;
          <span className="highlight">faça a sua pre-inscrição</span> para
          receber mais informações sobre o evento.
        </p>
      </div>
      <CTA id="jan-2021" isModal={false} />
    </div>
  );
};

export default WaitingList;
