import React from "react";
import About from "src/sections/About";
import Footer from "src/sections/Footer";
import WaitingList from "src/sections/WaitingList";
import SEO from "src/components/SEO";

import { NAME, BASE_URL } from "src/helpers/config";

const PreInscricao = () => {
  const seoProps = {
    title: `Projeto Dev 2021 - Pré-inscrição - ${NAME}`,
    description:
      "Participe do Projeto Dev 2021, faça agora mesmo sua pré-inscrição.",
    canonical: `${BASE_URL}/pre-inscricao/`,
    image: `${BASE_URL}/meta/og-image-projeto-dev-2021.jpg`,
  };

  return (
    <div>
      <SEO {...seoProps} />
      <WaitingList />
      <About />
      <Footer />
    </div>
  );
};

export default PreInscricao;
