import React from "react";

import Purchase from "./Purchase";
import Subscribe from "./Subscribe";

import { CHECKOUT_JS_497, CHECKOUT_ES6_47 } from "src/helpers/hotmart";
import {
  CONVERTKIT_FORM_ID_SUBSCRIPTION,
  CONVERTKIT_FORM_ID_NEWSLETTER,
  CONVERTKIT_FORM_ID_DICAS_JAVASCRIPT,
  CONVERTKIT_FORM_ID_ES6_SAMPLE,
  CONVERTKIT_FORM_ID_JAN_2021,
} from "src/helpers/config";

const CTA = ({ id, isModal }) => {
  switch (id) {
    case "live":
      return (
        <Subscribe
          title="Quero ser avisado"
          secondaryTitle="Me avise:"
          formId={CONVERTKIT_FORM_ID_SUBSCRIPTION}
          isModal={isModal}
        />
      );
    case "newsletter":
      return (
        <Subscribe
          title="Quero ser avisado"
          secondaryTitle="Me avise:"
          formId={CONVERTKIT_FORM_ID_NEWSLETTER}
          isModal={isModal}
        />
      );
    case "subscribe":
      return (
        <Subscribe
          title="Quero entrar para a lista de espera"
          secondaryTitle="Entrar para lista de espera:"
          formId={CONVERTKIT_FORM_ID_SUBSCRIPTION}
          isModal={isModal}
        />
      );
    case "jan-2021":
      return (
        <Subscribe
          title="Quero participar gratuitamente"
          secondaryTitle="Quero participar gratuitamente"
          formId={CONVERTKIT_FORM_ID_JAN_2021}
          isModal={isModal}
        />
      );
    case "apr-2021":
      return (
        <Subscribe
          title="Quero participar gratuitamente"
          secondaryTitle="Quero participar gratuitamente"
          formId={CONVERTKIT_FORM_ID_JAN_2021}
          isModal={isModal}
        />
      );

    case "purchase":
      return (
        <Purchase
          checkout={CHECKOUT_JS_497}
          title="Quero comprar o Curso"
          secondaryTitle="Dados pessoais"
          submitLabel="Continuar"
          formId={CONVERTKIT_FORM_ID_SUBSCRIPTION}
          isModal={isModal}
        />
      );
    case "es6":
      return (
        <Purchase
          checkout={CHECKOUT_ES6_47}
          title="Comprar o e-book"
          secondaryTitle="Dados pessoais"
          submitLabel="Continuar"
          formId={CONVERTKIT_FORM_ID_SUBSCRIPTION}
          isModal={isModal}
        />
      );
    case "es6-demo":
      return (
        <Subscribe
          title="Baixar Amostra Grátis do e-book"
          secondaryTitle="Dados pessoais"
          submitLabel="Continuar"
          formId={CONVERTKIT_FORM_ID_ES6_SAMPLE}
          isModal={isModal}
        />
      );
    case "dicas-javascript":
      return (
        <Subscribe
          title="Baixar Gratuitamente"
          secondaryTitle="Dados pessoais"
          submitLabel="Continuar"
          formId={CONVERTKIT_FORM_ID_DICAS_JAVASCRIPT}
          isModal={false}
        />
      );
    default:
      return null;
  }
};

export default CTA;
