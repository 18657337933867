import { getUrlParam } from "../helpers/gatsby";

export const CHECKOUT_JS_597 =
  "https://pay.hotmart.com/W40435487H?off=vujkremy&split=12"; // R$ 597,00
export const CHECKOUT_JS_497 =
  "https://pay.hotmart.com/W40435487H?off=48shygcp&split=12&offDiscount=MARATONA"; // R$ 497,00

export const CHECKOUT_ES6_47 =
  "https://pay.hotmart.com/L17765194L?checkoutMode=0&bid=1608512174238"; // R$ 47,00

export const CHECKOUT_REACT_997 =
  "https://pay.hotmart.com/R17090743G?checkoutMode=0&bid=1632098789357&offDiscount=MILZAO&split=12";

export const CHECKOUT_REACT_1497 =
  "https://pay.hotmart.com/R17090743G?checkoutMode=0&bid=1632098789357&offDiscount=QUINHENTAO&split=12";

export const getAdSource = () => {
  const MAX_SRC_CHARS = 30;
  try {
    const src = getUrlParam("src");

    if (src) return src.slice(MAX_SRC_CHARS * -1);

    const utmSource = getUrlParam("utm_source");
    const utmMedium = getUrlParam("utm_medium");
    const utmId = getUrlParam("utm_id");

    return [utmSource, utmMedium, utmId]
      .filter(Boolean)
      .join("|")
      .slice(MAX_SRC_CHARS * -1);
  } catch (e) {
    return "default-error";
  }
};

export const getCheckoutUrl = ({ url, name, email, src = null }) => {
  const adSource = getAdSource();

  if (name) url += `&name=${encodeURIComponent(name)}`;
  if (email) url += `&email=${encodeURIComponent(email)}`;
  if (adSource || src) url += `&src=${encodeURIComponent(src || adSource)}`;

  return url;
};

export const getJSPrices = id => {
  const productURL = "https://pay.hotmart.com/W40435487H";
  const prices = {
    p497: {
      full: 597.0,
      downpayment: 497.0,
      installment: 48.42,
      helperText: "Pouco mais de R$1,50 por dia! Preço especial",
      checkout: `${productURL}?off=48shygcp&checkoutMode=10&bid=1633311174584&split=12`,
      videoId: "oTCbspnEmCo",
    },
    p397: {
      full: 597.0,
      downpayment: 397.0,
      installment: 36.68,
      helperText: "Pouco mais de R$1,20 por dia! Vale muito a pena!",
      checkout: `${productURL}?off=yyskyo7o&checkoutMode=10&bid=1633311469685&split=12`,
      videoId: "oTCbspnEmCo",
    },
    p297: {
      full: 597.0,
      downpayment: 297.0,
      installment: 28.94,
      helperText:
        "Menos de 1 real por dia! O menor preço que esse curso já teve!",
      checkout: `${productURL}?off=9bqy7sv9&checkoutMode=10&bid=1633311480354&split=12`,
      videoId: "IY6nmKyWsU0",
    },
  };
  return prices[id] || prices.p497;
};

export const formatPrice = value => {
  return value.toLocaleString("pt-br", { minimumFractionDigits: 2 });
};
