import React, { useState } from "react";

import ConvertKitForm from "../ConvertKitForm";
import Icons from "../Icons";
import { getCheckoutUrl } from "src/helpers/hotmart";
import { isSSR } from "src/helpers/gatsby";
import { CONVERTKIT_FORM_ID_SUBSCRIPTION } from "src/helpers/config";
import { redirectTo } from "src/helpers/route";
import "./CTA.scss";

const Purchase = ({
  title,
  secondaryTitle,
  submitLabel,
  checkout,
  isModal,
  src,
}) => {
  const [showForm, setShowForm] = useState(false);

  const afterSubmit = data => {
    const props = { ...data, url: checkout, src };
    redirectTo(getCheckoutUrl(props));
    setTimeout(setShowForm, 600, false);
  };

  const isEnter = e => e.keyCode === 13;

  const itsSafe = (
    <span className="safe">
      <Icons id="shield" />
      Compra 100% Segura
    </span>
  );

  if (showForm && isModal && !isSSR) {
    document.body.classList.add("modal-open");
  } else if (!showForm && isModal && !isSSR) {
    document.body.classList.remove("modal-open");
  }

  if (showForm) {
    return (
      <div className={isModal ? "cta form modal" : "cta form"}>
        <div className="form-wrapper">
          <h6 className="serif text-highlight is-visible">
            {secondaryTitle || "Dados Pessoais:"}
          </h6>
          <ConvertKitForm
            form={CONVERTKIT_FORM_ID_SUBSCRIPTION}
            afterSubmit={afterSubmit}
            submitLabel={submitLabel || "Continuar"}
          />
          {itsSafe}

          <div className="payment-icons">
            <img
              loading="lazy"
              src="/images/MASTER_CARD.svg"
              alt="MASTER_CARD"
            />
            <img loading="lazy" src="/images/VISA.svg" alt="VISA" />
            <img loading="lazy" src="/images/AMEX.svg" alt="AMEX" />
            <img loading="lazy" src="/images/ELO.svg" alt="ELO" />
            <img loading="lazy" src="/images/HIPERCARD.svg" alt="HIPERCARD" />
            <img loading="lazy" src="/images/HIPER.svg" alt="HIPER" />
            <img loading="lazy" src="/images/DINERS.svg" alt="DINERS" />
            <img loading="lazy" src="/images/BILLET.svg" alt="Boleto" />
            <img loading="lazy" src="/images/PAYPAL.svg" alt="Paypal" />
          </div>
          <div
            role="button"
            tabIndex="0"
            className="cancel"
            onClick={e => setShowForm(false)}
            onKeyDown={e => (isEnter(e) ? setShowForm(false) : null)}
          >
            Cancelar
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="cta">
      <button
        onClick={e => setShowForm(true)}
        onKeyDown={e => (isEnter(e) ? setShowForm(true) : null)}
      >
        {title}
      </button>
      {itsSafe}
    </div>
  );
};
export default Purchase;
